<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo" v-translate>Aniversariantes</h1>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text class="modal-cadastro-form pa-3 pb-1">
              <v-row>
                <v-col dense cols="4" sm="4" class="pb-0">
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="birthdayMonthString"
                          :label="$gettext('Aniversariantes do Mês')"
                          @input="testeDate(birthdayMonthString)"
                          dense
                          readonly
                          v-on="on">
                          <template v-slot:append>
                            <v-icon v-on="on">
                              event
                            </v-icon>
                          </template>
                        </v-text-field>
                      </template>

                    <v-date-picker
                      v-model="birthdayMonth"
                      no-title
                      scrollable
                      locale="pt-br"
                      :type="'month'"
                      @input="closeModalDatePicker(birthdayMonth)"
                      :color="variables.colorPrimary">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="clearDateFilter(birthdayMonth)">Limpar</v-btn>
                        <v-btn text color="primary" @click="closeModalDatePicker(birthdayMonth)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col dense cols="4" sm="4" class="pb-0">
                  <v-select
                    v-model="typeFilter"
                    :items="listTypeFilter"
                    label="Filtro"
                    dense
                  />
                </v-col>


                <v-col dense cols="4" class="pb-0" v-if="typeFilter">
                  <autoCompleteEntities
                    :entityProps.sync="entity"
                    :typesEntities="[typeFilter]"
                    :inativos="inativos"
                    :label="'Entidade'"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="modal-cadastro-footer pt-0">
              <v-btn
                type="submit"
                :color="variables.colorPrimary"
                @click.prevent="submit"
                class="br-btn"
                :loading="loading"
                v-translate>Visualizar</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div class="box-table">
        
        <h1 class="titulo text-center mb-4 mt-3" v-translate>
          Relatório de Aniversariantes <br />
          {{ birthdayMonthString }}
        </h1>
        <v-data-table
          :headers="headers"
          :items="data"
          width="300px"
          height="auto"
          flat
          dense
        >

          <template v-slot:item.birth="{ item }">
            {{ item.birth | datas('DD/MM') }}
          </template>

          <template v-slot:item.value="{ item }">
            {{ calcMedia(item.value) | currency }}
          </template>

        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import { required  } from 'vuelidate/lib/validators'
// import Events from '@/core/service/events'
import { parseTime } from '@/core/service/utils'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
import messageValidation from './messageValidation'

/**
 * MIXIN
 */
// import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'relatoriosAniversariantes'

moment.locale('pt-BR')

export default {
  name: 'Cadastro',
  components: {
    autoCompleteEntities: () => import('@/views/components/autoCompleteEntities')
  },
  mixins: [validationMixin],
  directives: {mask},
  data: () => ({

    loading: false,
    loadingTable: false,
    birthdayMonthString: '',
    dtFinal: '',
    birthdayMonth: '',
    inativos: null,
    hasMonthOrDate: 1,
    headerExcel:  ['Código da Entidade', 'Nome da Entidade', 'Caixa', 'Comissão', 'Deve Anterior', 'Deve Atual', 'Débito', 'Prêmios', 'Proposta', 'Saldo'],
    menuDate: false,
    entity: {},

    listTypeFilter: [
      { text: 'Sem filtro', value: null },
      { text: 'Supervisor', value: 2 },
      { text: 'Promotor', value: 3 }
    ],
    typeFilter: null,
    headers: [
      { sortable: true, class: 'table-header', text: 'Código', value: 'entity_cod' },
      { sortable: true, class: 'table-header', text: 'Revendedor', value: 'entity_name' },
      { sortable: true, class: 'table-header', text: 'Data de Nascimento', value: 'birth' },
      { sortable: true, class: 'table-header', text: 'Média de Venda', value: 'value' }
    ]
  }),

  watch: {
    hasMonthOrDate (val) {
      this.setHasMonthOrDate(val)
    },

    typeFilter () {
      this.entity = {}
    }
  },

  computed: {
    ...mapGetters(namespaceStore, ['data']),
    variables: () => variables,
    ...messageValidation
  },

  methods: {
    ...mapActions(namespaceStore, ['getData']),

    init() {},

    calcMedia (total) {
      const qtdDias =  parseInt(moment(this.birthdayMonth, 'YYYY-MM').endOf('month').format('DD'))
      console.log(qtdDias)
      return (total * -1) / qtdDias
    },

    submit () {
      if (this.$v.birthdayMonth.$invalid) { 
        this.$v.birthdayMonth.$touch()
        return false 
      }

      this.loading = true

      let month = ''
      if (moment(this.birthdayMonth, 'YYYY-MM', true)) {
        month = moment(this.birthdayMonth, 'YYYY-MM').format('MM')
      }
  
      this.getData({ month, entityId: this.entity.value }).then(() => {
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },

    testeDate (birthdayMonthString) {
      const testeDate =  moment(birthdayMonthString, 'MMMM', true)
      if (testeDate.isValid()) {
        this.birthdayMonth = moment(birthdayMonthString, 'MMMM').format('YYYY-MM')
      }
    },

    closeModalDatePicker (date) {
      this.birthdayMonthString = moment(date, 'YYYY-MM').format('MMMM')
    },

    handleDownload() {
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const header = this.headerExcel
        const filterVal = ['entity_cod', 'entity_name', 'caixa', 'comissao', 'deve_ant', 'deve_atual', 'pagto', 'premio', 'proposta', 'saldo']
        const data = this.formatJson(filterVal)
        excel.export_json_to_excel({
          header,
          data,
          filename: 'gecom',
          autoWidth: true
        })
        this.downloadLoading = false
      })
    },
    
    formatJson(filterVal) {
      let data = []
      switch (this.typeGecom) {
        case (null) :
          data = this.transmissoes.results
          break

        case (2) :
          this.transmissoes.results.forEach(item => {
            item.promotores.forEach(promotor => {
              promotor.supervisor = `${item.entity_cod} - ${item.entity_name}`
              data.push(promotor)
            })
          })

          filterVal.unshift('supervisor')
          this.headerExcel.unshift('Supervisor')
          break

        case (3) :
        case (4) :
          this.transmissoes.results.forEach(item => {
            item.promotores.forEach(promotor => {
              promotor.supervisor = `${item.entity_cod} - ${item.entity_name}`

              promotor.agencias.forEach(agencia => {
                agencia.supervisor = promotor.supervisor
                agencia.promotor = `${promotor.entity_cod} - ${promotor.entity_name}`
                data.push(agencia)
              })
            })
          })

          filterVal.unshift('promotor')
          this.headerExcel.unshift('Promotor')

          filterVal.unshift('supervisor')
          this.headerExcel.unshift('Supervisor')
          break
      }

      return data.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    }
  },

  validations () {
    return  {
      birthdayMonth: { required },
    }
  },

  beforeDestroy () {
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }

    &.box-table-gecom {
      .v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
